import React from "react"
import "./navbar.scss"

const Navbar = () => {
  return (
    <nav role="navigation">
      <div id="menuToggle">
        {/* <!--
    A fake / hidden checkbox is used as click reciever,
    so you can use the :checked selector on it.
    --> */}
        <input type="checkbox" aria-label="Menú" />

        {/* <!--
    Some spans to act as a hamburger.
    
    They are acting like a real hamburger,
    not that McDonalds stuff.
    --> */}
        <div className="burger-wrap"></div>
        <span></span>
        <span></span>
        <span></span>

        {/* <!--
    Too bad the menu has to be inside of the button
    but hey, it's pure CSS magic.
    --> */}
        <ul id="menu">
          <li>
            <a href="http://www.mostazaweb.com.ar/home/1">HOME</a>
          </li>
          <li>
            <a href="http://www.mostazaweb.com.ar/home/3">MOSTAZA APP</a>
          </li>
          <li>
            <a href="http://www.mostazaweb.com.ar/home/4">LOCALES</a>
          </li>
          <li>
            <a href="http://www.mostazaweb.com.ar/menues">MENU</a>
          </li>
          <li>
            <a href="http://www.mostazaweb.com.ar/mostaza-kids">
              CAJITA MOSTAZA
            </a>
          </li>
          <li>
            <a href="http://www.mostazaweb.com.ar/campanas">CAMPAÑAS</a>
          </li>
          <li>
            <a href="/">FRANQUICIAS</a>
          </li>
          <li>
            <a href="http://www.mostazaweb.com.ar/trabaja-en-mostaza">
              TRABAJÁ EN MOSTAZA
            </a>
          </li>
          <li>
            <a href="http://www.mostazaweb.com.ar/contacto">
              CONTACTO Y SUGERENCIAS
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
